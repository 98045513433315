import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'
import { css } from '@styled-system/css'

import * as Configuration from './configuration'
import { AppVersion } from './app-version'
import { ClientType } from './constants'
import { GameProviderImages } from './game-provider-images'
import { HtmlContent } from './html-content'
import { PaymentProviderImages } from './payment-provider-images'
import { SocialMediaIcons } from './social-media-icons'
import { XcmSeal } from './xcm-seal'
import { useGameInfoUrlQuery } from './use-game-info-url-query'

const LinkWrapper = styled.div`
  ${css({
    p: 1,
  })}
  opacity: 0.5;
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

const translationKeys = [
  'footer.terms-and-conditions',
  'footer.about-us',
  'footer.cookie-policy',
  'footer.faq',
  'footer.privacy-policy',
  'footer.responsible-gaming',
  'footer.security',
  'footer.affiliate',
  'footer.legal-text.curacao',
  '18-plus',
  'sweetspot-logo',
]

export function Footer() {
  const translate = Herz.I18n.useTranslate()
  const gameInfoQuery = useGameInfoUrlQuery()
  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const isMobile = ClientType.MOBILE_BROWSER === clientType
  const { authenticated } = Herz.Auth.useSession()

  /**
   * Hides the footer:
   * - When playing any game on mobile
   */

  if (
    isMobile &&
    ((gameInfoQuery.referrer === 'live-casino' && authenticated) ||
      gameInfoQuery.referrer === 'casino')
  ) {
    return null
  }

  return (
    <Common.Box
      display="flex"
      backgroundColor="footer-background"
      padding={[1, 2]}
      flexDirection="column"
      alignItems="center"
      as="footer"
    >
      <Common.Box
        as="nav"
        color="g-text"
        display="flex"
        flexWrap="wrap"
        fontSize={1}
        justifyContent="center"
        pt={0}
        opacity="0.8"
      >
        <ReactRouter.Link to="/terms-and-conditions">
          <LinkWrapper>{translate('footer.terms-and-conditions')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/about">
          <LinkWrapper>{translate('footer.about-us')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/faq">
          <LinkWrapper>{translate('footer.faq')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/privacy-policy">
          <LinkWrapper>{translate('footer.privacy-policy')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/responsible-gaming">
          <LinkWrapper>{translate('footer.responsible-gaming')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/security">
          <LinkWrapper>{translate('footer.security')}</LinkWrapper>
        </ReactRouter.Link>
        <a
          href="https://heropartners.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkWrapper>{translate('footer.affiliate')}</LinkWrapper>
        </a>
      </Common.Box>
      <LazyLoad once offset={100}>
        <PaymentProviderImages />
        <GameProviderImages />
      </LazyLoad>
      <Common.Box
        fontSize={1}
        color="g-text"
        opacity="0.5"
        pt={2}
        textAlign="center"
        mx="auto"
        maxWidth="700px"
      >
        <HtmlContent
          as="small"
          html={{
            __html: translate('footer.legal-text.curacao'),
          }}
        />
      </Common.Box>
      <LazyLoad once offset={100}>
        <Common.Box mt={2}>
          <SocialMediaIcons />
        </Common.Box>
        <Common.Box
          display="flex"
          pt={2}
          justifyContent="center"
          alignItems="center"
        >
          <Common.Box px={1}>
            <XcmSeal />
          </Common.Box>
          <Common.Box
            as="a"
            href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJakp0Y0dsU2NEUmFiRTQyYnpWQ1EwUnpibUYyVEVFOVBTSXNJblpoYkhWbElqb2lLM05rV25GRWNrdHpjbE5YVFhBMU9EUmtRa1paUVQwOUlpd2liV0ZqSWpvaVl6UXhaR0ZpTldWaFpEWXdNalV4WW1RM09XWm1OekV5T0RZMlpUWTBNMkkxWmpRM01XWmhOVEV6TW1JM09XSXhOemRrWkdKaU9EaG1NamsyTkRKa055SXNJblJoWnlJNklpSjk="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/gcb.avif" alt="GCB license" height="42.5px" />
          </Common.Box>
          <ReactRouter.Link to="/responsible-gaming">
            <Common.Box
              as="img"
              alt="18+"
              src={translate('18-plus')}
              height="34px"
              mx={1}
            />
          </ReactRouter.Link>
        </Common.Box>
      </LazyLoad>
      <Common.Box textAlign="center">
        <AppVersion />
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(translationKeys, Footer)

// for @loadable/components
export default Footer
